import {Injectable} from '@angular/core';
import {TenantService} from '../../services/tenant.service';
import {CommunityRoom} from '../../models/community/community-room.model';
import {BehaviorSubject, first} from 'rxjs';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  Firestore,
  getDocs, query,
  setDoc,
  updateDoc, where,
  writeBatch
} from '@angular/fire/firestore';
import {createFirestoreId} from '../../common/firestore-utils';

@Injectable({
  providedIn: 'root'
})
export class ManageRoomService {

  rooms$ = new BehaviorSubject<CommunityRoom[]>([]);

  constructor(
    private db: Firestore,
    private tenant: TenantService) {
  }

  generateRoomId() {
    return createFirestoreId(this.db)
    }

  async saveBannerUrl(roomId: string, bannerUrl: string) {
    const path = `schools/${this.tenant.id}/communityRooms`;

    const docRef = doc(this.db, path + "/" + roomId);

    const rooms = this.rooms$.getValue();
    const room = rooms.find((room) => room.id === roomId);
    room.bannerUrl = bannerUrl;
    this.rooms$.next(rooms);

    await updateDoc(docRef, { bannerUrl });
  }

  async loadCommunityRooms() {
    const path = `schools/${this.tenant.id}/communityRooms`;

    const result = await collectionData(collection(this.db, path), {idField: "id"}).pipe(first()).toPromise();

    const roomsDoc = result.map((communityRoom) => {
      return communityRoom
    });

    this.rooms$.next(roomsDoc as CommunityRoom[]);

    return roomsDoc;
  }

  async createCommunityRoom(communityRoom: CommunityRoom) {

    const path = `schools/${this.tenant.id}/communityRooms`;

    if(!communityRoom?.id) {
      communityRoom.id = createFirestoreId(this.db)
    }

    const docRef = doc(this.db, path + "/" +communityRoom.id);

    await setDoc(docRef, communityRoom);

    const rooms = this.rooms$.getValue();
    rooms.push(communityRoom);
    this.rooms$.next(rooms);

  }

  async updateCommunityRoom(id: string, communityRoom: CommunityRoom) {

    const path = `schools/${this.tenant.id}/communityRooms`;

    const docRef = doc(this.db, path + "/" + id);

    await setDoc(docRef, {...communityRoom}, {merge: true});

    const rooms = this.rooms$.getValue();
    const index = rooms.findIndex((room) => room.id === id);

    rooms[index] = communityRoom;

    this.rooms$.next(rooms);

  }

  async deleteCommunityRoom(roomId: string) {

    const path = `schools/${this.tenant.id}/communityRooms`;

    await this.deletePostsByRoomId(roomId)

    const docRef = doc(this.db, path + "/" + roomId.toString());
    await deleteDoc(docRef);

    const rooms = this.rooms$.getValue();
    const index = rooms.findIndex((room) => room.id === roomId);

    rooms.splice(index, 1);

    this.rooms$.next(rooms);
  }

  async deletePostsByRoomId(roomId: string) {
    const path = `schools/${this.tenant.id}/communityPosts`;

    const batch = writeBatch(this.db);

    const queryConstraints = [
      where("roomId", "==", roomId)
    ];

    const postsQuery = query(collection(this.db, path), ...queryConstraints);
    const postsSnapshot = await getDocs(postsQuery);

    postsSnapshot.forEach((postDoc) => {
      if (postDoc.exists()) {
        batch.delete(postDoc.ref);
      }
    });

    await batch.commit();
  }



}
